<template>
  <Invoices />
</template>

<script>
import Invoices from "@/components/user/Invoices";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Invoices,
  },
  metaInfo: {
    title: "Mijn facturen beheren| Kantoofflex",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>