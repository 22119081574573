<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div style="margin-bottom:10px">
        <div style="margin-bottom:10px">
          <v-btn color="primary" @click="goHome()">
            Ga terug
          </v-btn>
        </div>
      </div>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="invoices"
        :single-select="singleSelect"
        item-key="name"
        show-select
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Facturen overzicht</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn
         
            color="blue"
            class="ma-2 white--text"
            @click="generateInvoice(item.office_id.office_id)"
          >
            Download
            <v-icon right dark>
              mdi-cloud-download
            </v-icon>
          </v-btn>
        </template>
        <template #[`item.createDate`]="{item}">
            {{item.createDate
              ? new Date(item.createDate).toLocaleDateString()
              : ""}}
          </template>
          <template #[`item.appointment_date`]="{item}">
            {{item.appointment_date
              ? new Date(item.appointment_date).toLocaleDateString()
              : ""}}
          </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      generatedInvoice:[],
      singleSelect: false,
      selected: [],
      invoices: [],
      headers: [
        {
          text: "Factuurnummer",
          value: "reservation_number",
          sorble: false,
        },
        {
          text: "Factuurdatum",
          value: "createDate",
          ble: false,
        },
        {
          text: "Boekingsdatum",
          value: "appointment_date",
          ble: false,
        },
        {
          text: "Bedrag",
          value: "bedrag",
          ble: false,
        },
        {
          text: "Factuur dowloaden",
          value: "action",
          ble: false,
        },
      ],
    };
  },
  async mounted() {
    await this.getInvoices();
  },
  methods: {
    goHome() {
      this.$router.push("/KlantPortaal");
    },
    async getInvoices() {
      const response = await axios.get(
        "https://server.kantoorflex.nl/api/rest/v2/appointment/approved/user/invoices"
      );
      this.invoices = response.data;
    },
    
    async generateInvoice(office) {
      const response = await axios.get(
        `https://server.kantoorflex.nl/api/rest/v2/appointment/pdf/${office}`, {responseType: "blob"}
      );
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'Invoice.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
  },
};
</script>
